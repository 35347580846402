import React from 'react'
import MediaQuery from "react-responsive";
import portrait01 from '../assets/images/portrait/2018/01.jpg'
import portrait02 from '../assets/images/portrait/2018/02.jpg'
import portrait03 from '../assets/images/portrait/2018/03.jpg'
import portrait04 from '../assets/images/portrait/2018/04.jpg'
import portrait05 from '../assets/images/portrait/2018/05.jpg'
import portrait06 from '../assets/images/portrait/2018/06.jpg'
import portrait07 from '../assets/images/portrait/2018/07.jpg'
import portrait08 from '../assets/images/portrait/2018/08.jpg'
import portrait09 from '../assets/images/portrait/2018/09.jpg'
import portrait10 from '../assets/images/portrait/2018/10.jpg'
import portrait11 from '../assets/images/portrait/2018/11.jpg'
import thumb01 from '../assets/images/portrait/2018/01.jpg'
import thumb02 from '../assets/images/portrait/2018/02.jpg'
import thumb03 from '../assets/images/portrait/2018/03.jpg'
import thumb04 from '../assets/images/portrait/2018/04.jpg'
import thumb05 from '../assets/images/portrait/2018/05.jpg'
import thumb06 from '../assets/images/portrait/2018/06.jpg'
import thumb07 from '../assets/images/portrait/2018/07.jpg'
import thumb08 from '../assets/images/portrait/2018/08.jpg'
import thumb09 from '../assets/images/portrait/2018/09.jpg'
import thumb10 from '../assets/images/portrait/2018/10.jpg'
import thumb11 from '../assets/images/portrait/2018/11.jpg'
import pcthumb01 from '../assets/images/portrait/2018/thumbnail/01.jpg'
import pcthumb02 from '../assets/images/portrait/2018/thumbnail/02.jpg'
import pcthumb03 from '../assets/images/portrait/2018/thumbnail/03.jpg'
import pcthumb04 from '../assets/images/portrait/2018/thumbnail/04.jpg'
import pcthumb05 from '../assets/images/portrait/2018/thumbnail/05.jpg'
import pcthumb06 from '../assets/images/portrait/2018/thumbnail/06.jpg'
import pcthumb07 from '../assets/images/portrait/2018/thumbnail/07.jpg'
import pcthumb08 from '../assets/images/portrait/2018/thumbnail/08.jpg'
import pcthumb09 from '../assets/images/portrait/2018/thumbnail/09.jpg'
import pcthumb10 from '../assets/images/portrait/2018/thumbnail/10.jpg'
import pcthumb11 from '../assets/images/portrait/2018/thumbnail/11.jpg'
import Gallery from '../components/Gallery'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Worksnav from '../components/Worksnav'


const DEFAULT_IMAGES2 = [
  {
    id: '1',
    source: portrait01,
    thumbnail: thumb01,
    caption: 'Photo 1',
    description: 'Miyu Teshima',
  },
  {
    id: '2',
    source: portrait02,
    thumbnail: thumb02,
    caption: 'Photo 2',
    description: 'phai',
  },
  {
    id: '3',
    source: portrait03,
    thumbnail: thumb03,
    caption: 'Photo 3',
    description: 'King Gnu for i-D japan no.5',
  },
  {
    id: '4',
    source: portrait04,
    thumbnail: thumb04,
    caption: 'Photo 4',
    description: 'Daiki Tsuneta and Satoru Iguchi for i-D japan no.5',
  },
  {
    id: '5',
    source: portrait05,
    thumbnail: thumb05,
    caption: 'Photo 5',
    description: 'Hana Matsumoto',
  },
  {
    id: '6',
    source: portrait06,
    thumbnail: thumb06,
    caption: 'Photo 6',
    description: 'Templay for i-D Japan',
  },
  {
    id: '7',
    source: portrait07,
    thumbnail: thumb07,
    caption: 'Photo 7',
    description: 'Vietnam 2018',
  },
  {
    id: '8',
    source: portrait08,
    thumbnail: thumb08,
    caption: 'Photo 8',
    description: 'Taichi Saotome for "The work of actors and painters"(2018)',
  },
  {
    id: '9',
    source: portrait09,
    thumbnail: thumb09,
    caption: 'Photo 9',
    description: 'Pecori for NiL Magazine',
  },
  {
    id: '10',
    source: portrait10,
    thumbnail: thumb10,
    caption: 'Photo 10',
    description: 'Riku Hagiwara',
  },
  {
    id: '11',
    source: portrait11,
    thumbnail: thumb11,
    caption: 'Photo 11',
    description: 'Sawa',
  },
]
const DEFAULT_IMAGES = [
  {
    id: '1',
    source: portrait01,
    thumbnail: pcthumb01,
    caption: 'Photo 1',
    description: 'Miyu Teshima',
  },
  {
    id: '2',
    source: portrait02,
    thumbnail: pcthumb02,
    caption: 'Photo 2',
    description: 'phai',
  },
  {
    id: '3',
    source: portrait03,
    thumbnail: pcthumb03,
    caption: 'Photo 3',
    description: 'King Gnu for i-D japan no.5',
  },
  {
    id: '4',
    source: portrait04,
    thumbnail: pcthumb04,
    caption: 'Photo 4',
    description: 'Daiki Tsuneta and Satoru Iguchi for i-D japan no.5',
  },
  {
    id: '5',
    source: portrait05,
    thumbnail: pcthumb05,
    caption: 'Photo 5',
    description: 'Hana Matsumoto',
  },
  {
    id: '6',
    source: portrait06,
    thumbnail: pcthumb06,
    caption: 'Photo 6',
    description: 'Templay for i-D Japan',
  },
  {
    id: '7',
    source: portrait07,
    thumbnail: pcthumb07,
    caption: 'Photo 7',
    description: 'Vietnam 2018',
  },
  {
    id: '8',
    source: portrait08,
    thumbnail: pcthumb08,
    caption: 'Photo 8',
    description: 'Taichi Saotome for "The work of actors and painters"(2018)',
  },
  {
    id: '9',
    source: portrait09,
    thumbnail: pcthumb09,
    caption: 'Photo 9',
    description: 'Pecori for NiL Magazine',
  },
  {
    id: '10',
    source: portrait10,
    thumbnail: pcthumb10,
    caption: 'Photo 10',
    description: 'Riku Hagiwara',
  },
  {
    id: '11',
    source: portrait11,
    thumbnail: pcthumb11,
    caption: 'Photo 11',
    description: 'Sawa',
  },
]

const siteTitle = 'Onodera Ryo | portrait'

const Portrait2018 = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      nonClass: '',
    }
  }
  toggleNon = () => {
    console.log("111");
    this.setState({
      nonClass: 'is-active',
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.toggleNon();
    },700);
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
        </Helmet>
        <div id="main">
          <div className="portrait">
            <Worksnav>
              Portrait 2018
            </Worksnav>
            <div class={`gall ${this.state.nonClass}`}>
            <MediaQuery query="(min-width: 767px)">
              <Gallery
                      images={DEFAULT_IMAGES.map(
                        ({ id, source, thumbnail, caption, description }) => ({
                          source,
                          thumbnail,
                          caption,
                          description,
                        })
                      )}
              />
            </MediaQuery>
            <MediaQuery query="(max-width: 767px)">
              <Gallery
                      images={DEFAULT_IMAGES2.map(
                        ({ id, source, thumbnail, caption, description }) => ({
                          source,
                          thumbnail,
                          caption,
                          description,
                        })
                      )}
              />
            </MediaQuery>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Portrait2018